@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-stats-detail {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  overflow: hidden;

  > div.form {
    @include size-width-height(0, 100%);
    @include transition-ui("width, min-width");
    width: 0;
    overflow: hidden;

    form {
      width: 320px;
      box-sizing: border-box;
      padding: 32px 24px 0 24px;
    }

    &-toggled {
      @include size-width-height(320px, 100%);
    }
  }

  > div.chart {
    @include flex-auto-fill;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding: 16px 24px 24px 24px;
    overflow: hidden;
  }
}
