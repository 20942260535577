@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-code-area {
  @include display-flex-row;
  border-radius: 4px;

  div.cm-editor {
    width: 100%;
    outline: none;
  }

  &-bright {
    background-color: $color-field-02;
  }

}
