@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "~muklit/styles/positions";

div.invipo-traffic-pentlogram-report {
  @include flex-auto-fill;
  height: 100%;

  div.form {
    div.fieldset-general {
      @include display-flex-row;
      flex-wrap: wrap;

      div.field-interval {
        margin-right: 32px;
      }
    }
  }

  div.chart {
    position: relative;
    z-index: 1;
  }

  div.pentlogram {
    margin: 0 auto;
    padding: 24px 10px 32px 10px;
    overflow: hidden;

    svg {
      * {
        display: none;
      }

      text {
        @include font-proxima-medium;
      }

      /*
       * Autogenerated SVG export styles
       */
      .st4 {
        opacity: 0.7;
        fill: none;
        stroke: #F7931E;
        stroke-miterlimit: 10;
      }

      .st5 {
        opacity: 0.7;
        fill: none;
        stroke: #F7931E;
        stroke-miterlimit: 10;
      }

      .st6 {
        opacity: 0.7;
        fill: none;
        stroke: #0000FF;
        stroke-miterlimit: 10;
      }

      .st7 {
        opacity: 0.7;
        fill: none;
        stroke: #0000FF;
        stroke-miterlimit: 10;
      }

      .st8 {
        opacity: 0.7;
        fill: none;
        stroke: #E80711;
        stroke-miterlimit: 10;
      }

      .st9 {
        opacity: 0.7;
        fill: none;
        stroke: #E80711;
        stroke-miterlimit: 10;
      }

      .st10 {
        opacity: 0.7;
        fill: none;
        stroke: #00B300;
        stroke-miterlimit: 10;
      }

      .st11 {
        opacity: 0.7;
        fill: none;
        stroke: #00B300;
        stroke-miterlimit: 10;
      }

      .st14 {
        font-size: 11px;
      }

      .st15 {
        fill: none;
        stroke: #000000;
        stroke-miterlimit: 10;
      }

      .st17 {
        font-size: 13px;
      }

      .st18 {
        font-size: 16px;
      }
    }
  }
}

