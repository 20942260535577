@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-hanoi-traffic-incident-browser {
  @include flex-auto-fill;
  height: 100%;
  overflow: hidden;

  @media print {
    display: none;
  }
}
