body {
  transition: all 300ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: opacity;
  display: flex;
  flex-flow: row;
  overflow: hidden;
}
body.view-home {
  background-color: #090c25;
}
body.debug div[component] {
  box-sizing: border-box;
  border: 1px dashed #dd0000 !important;
}
body.project-crossulmde.view-home, body.project-crossulmde.view-dashboard {
  background-size: cover;
  background-color: #181121;
  background-image: url("~muklit/assets/images/cities/ulm-bg.jpg");
}
body.project-crosshrkcz.view-home, body.project-crosshrkcz.view-dashboard {
  background-size: cover;
  background-color: #14223c;
  background-image: url("~muklit/assets/images/cities/hradec_kralove-bg.jpg");
}
body.project-alamenvisk.view-home {
  background-size: cover;
  background-color: #355178;
  background-image: url("~muklit/assets/images/cities/esbjerg-02-bg.jpg");
}
body.project-crossesbdk.view-home, body.project-crossesbdk.view-dashboard {
  background-size: cover;
  background-color: #355178;
  background-image: url("~muklit/assets/images/cities/esbjerg-02-bg.jpg");
}
body.project-verdoalbdk.view-home {
  background-size: cover;
  background-color: #092044;
  background-image: url("~muklit/assets/images/cities/aalborg-bg.jpg");
}
body.project-krnappilcz.view-home, body.project-krnappilcz.view-dashboard {
  background-size: cover;
  background-color: #105a42;
  background-image: url("~muklit/assets/images/cities/krnap-bg.jpg");
}
body.project-crosshavcz.view-home, body.project-crosshavcz.view-dashboard {
  background-size: cover;
  background-color: #1e1e36;
  background-image: url("~muklit/assets/images/cities/havirov-bg.jpg");
}
body.project-crossvejdk.view-home, body.project-crossvejdk.view-dashboard {
  background-size: cover;
  background-color: #182f58;
  background-image: url("~muklit/assets/images/cities/vejle-bg.jpg");
}
body.project-crossciscz.view-home {
  background-size: cover;
  background-color: #142981;
  background-image: url("~muklit/assets/images/cities/cross-03-bg.jpg");
}
body.project-crossrsdcz.view-home {
  background-size: cover;
  background-color: #32617e;
  background-image: url("~muklit/assets/images/cities/RSD-bg.jpg");
}
body.project-dotswpregr.view-home, body.project-dotswpregr.view-dashboard {
  background-size: cover;
  background-color: #033f73;
  background-image: url("~muklit/assets/images/cities/preveza-bg.jpg");
}
body.project-dotswkalgr.view-home, body.project-dotswkalgr.view-dashboard {
  background-size: cover;
  background-color: #0d3357;
  background-image: url("~muklit/assets/images/cities/kalamaria-bg.jpg");
}
body.project-dotswpatgr.view-home, body.project-dotswpatgr.view-dashboard {
  background-size: cover;
  background-color: #04345c;
  background-image: url("~muklit/assets/images/cities/patras-bg.jpg");
}
body.project-dotswvolgr.view-home, body.project-dotswvolgr.view-dashboard {
  background-size: cover;
  background-color: #04345c;
  background-image: url("~muklit/assets/images/cities/volos-bg.jpg");
}
body.project-crossegogr.view-home {
  background-size: cover;
  background-color: #142b21;
  background-image: url("~muklit/assets/images/cities/egnatia-odos-bg.jpg");
}
body.project-crosspocvn.view-home {
  background-size: cover;
  background-color: #1c263f;
  background-image: url("~muklit/assets/images/cities/vietnam-bg.jpg");
}
body.project-inmeehanvn.view-home, body.project-inmeehanvn.view-dashboard {
  background-size: cover;
  background-color: #1c263f;
  background-image: url("~muklit/assets/images/cities/hanoi-bg.jpg");
}
body.project-inmeeangvn.view-home, body.project-inmeeangvn.view-dashboard {
  background-size: cover;
  background-color: #1c263f;
  background-image: url("~muklit/assets/images/cities/an_giang-bg.jpg");
}