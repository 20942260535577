@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-traffic-light-controller-diagram {
  @include display-flex-row;
  height: 100%;
  position: relative;
  overflow: hidden;

  div.diagram {
    @include flex-auto-fill;
    @include display-flex-column;
    @include transition-ui(opacity);
    height: 100%;
    width: 400px;
    box-sizing: border-box;
    padding: 0 0 24px 24px;
    opacity: 0;

    div.header {
      @include display-flex-row;
      padding: 24px 0;

      div.property {
        @include cursor-pointer;
        width: 80px;
        overflow: hidden;
        margin-right: 12px;

        div.label {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
        }

        div.value {
          @include font-proxima-medium;
          @include font-size-color(14px, $color-text-01);
          margin: 2px 0;
        }
      }
    }

    div.content {
      @include display-flex-row;
      @include flex-auto-fill;
      align-content: flex-start;
      overflow: auto;

      div.groups {
        @include size-width-height(120px, 100%);
        box-sizing: border-box;
        overflow: hidden;
        z-index: 2;
        padding: 20px 0 0 0;
        border-right: 1px solid $color-skeleton-01;

        div.name {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
          user-select: none;
          height: 20px;
          box-sizing: border-box;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 2px 0 0 0;
        }
      }

      div.frames {
        @include display-flex-row;
        @include flex-auto-fill;
        overflow: auto;
        padding: 0 0 24px 0;

        div.frame {
          width: 12px;
          min-width: 12px;
          box-sizing: border-box;
          animation: keyframes-diagram-frame-fade-in 300ms;

          div.second {
            @include font-proxima-regular;
            @include size-width-height(12px, 20px);
            @include font-size-color(12px, $color-text-03);
            user-select: none;
            box-sizing: border-box;
            text-align: center;
            white-space: nowrap;
            padding-top: 4px;

            &-0 {
              @include font-proxima-semi-bold;
              color: $color-text-01;
            }
          }

          div.bar {
            @include size-width-height(12px, 20px);
            position: relative;
            box-sizing: border-box;
            border-top: 2px solid $color-ui-background;
            border-bottom: 2px solid $color-ui-background;

            &:before {
              content: "";
              position: absolute;
            }

            &:after {
              content: "";
              position: absolute;
            }

            &-state-red {
              background-color: $color-traffic-control-light-red;
              border-top: 6px solid $color-ui-background;
              border-bottom: 6px solid $color-ui-background;
            }

            &-state-green {
              background-color: $color-traffic-control-light-green;
            }

            &-state-green1,
            &-state-green2,
            &-state-green-overlap,
            &-state-green-postproductive {

              &:before {
                left: 2px;
                top: 4px;
                width: 8px;
                height: 8px;
                background-color: $color-traffic-control-light-green;
                transform: rotate(45deg);
                transform-origin: 50% 50%;
                z-index: -1;
              }
            }

            &-state-yellow {
              background-color: $color-traffic-control-light-yellow;
            }

            &-state-yellow-flashing {
              background-color: $color-traffic-control-light-yellow;
            }

            &-state-red-yellow {
              background-color: $color-traffic-control-light-yellow;

              &:after {
                top: 0;
                width: 100%;
                height: 50%;
                background-color: $color-traffic-control-light-red;
              }
            }

            &-state-yellow-green {
              background-color: $color-traffic-control-light-green;

              &:after {
                top: 0;
                width: 100%;
                height: 50%;
                background-color: $color-traffic-control-light-yellow;
              }
            }

            &-state-dark {
              background-color: $color-icon-02;
              border-top: 6px solid $color-ui-background;
              border-bottom: 6px solid $color-ui-background;
            }

            &-detector {
              background-color: $color-ui-background;

              &-occupied {
                &:after {
                  top: 4px;
                  width: 100%;
                  height: 3px;
                  background-color: $color-traffic_control-light-yellow;
                }
              }

              &-call {
                &:before {
                  top: 10px;
                  width: 100%;
                  height: 3px;
                  background-color: $color-traffic_control-mode-special;
                }
              }
            }

            &-preference {
              &-requested {
                &:after {
                  left: 1px;
                  top: 4px;
                  width: 8px;
                  height: 8px;
                  background-color: $color-text-03;
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }
    }
  }

  div.map {
    @include size-width-height(766px, 100%);
    @include transition-ui(opacity);
    opacity: 0;
    margin: 0 0 0 24px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
  }

  &-started {
    div.diagram {
      opacity: 1;
    }

    div.map {
      opacity: 1;
    }
  }

}
