@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../styles/colors";

div.partial-plate-number {

  span {
    display: inline-block;
    font-family: "Consolas", "Lucida Sans", sans-serif;
    font-size: 12px;
    padding: 0 2px;
    color: $color-text-02;
    border: 1px solid $color-text-02;
    border-radius: 4px;
  }

  &-registry {
    @include cursor-pointer;
  }
}
