@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../styles/colors";

div.partial-status-formatter {
  @include display-flex-row;
  align-items: center;

  div.status {
    @include size-width-height(10px, 10px);
    border-radius: 50%;
    background-color: #dddddd;
    margin: 0 6px 0 2px;

    &-ok {
      background-color: $color-status-ok;
    }

    &-warn {
      background-color: $color-status-warn;
    }

    &-error {
      background-color: $color-status-error;
    }

    &-unreachable {
      background-color: $color-status-unreachable;
    }

    &-disconnected {
      background-color: $color-status-disconnected;
    }

    &-disabled {
      background-color: $color-status-disabled;
    }
  }

  div.timestamp {
    @include font-proxima-regular;
    font-size: 14px;
    margin-right: 6px;
  }

  div.label {
    @include font-proxima-regular;
    font-size: 14px;
    margin-right: 6px;
  }

  div.text {
    @include font-proxima-regular;
    font-size: 14px;
  }

  &-style-light {
    div.timestamp {
      color: $color-text-01;
    }

    div.label {
      color: $color-text-01;
    }

    div.text {
      color: $color-text-03;
    }
  }

  &-style-dark {
    div.timestamp {
      color: $color-text-01-dark;
    }

    div.label {
      color: $color-text-01-dark;
    }

    div.text {
      color: $color-text-03-dark;
    }
  }
}
