@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-wim-violation-detail {
  @include size-width-height(460px, 100%);
  box-sizing: border-box;

  @media print {
    display: none;
  }
}
