@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-traffic-incident-offense {
  position: absolute;
  left: 54px + 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  background-color: $color-field-01;

  div.content {
    scrollbar-gutter: stable;

    div.document {
      // A4 parameters
      width: 210mm;
      min-height: 290mm;
      margin: 32px auto;
      box-sizing: border-box;
      padding: 10mm 15mm;
      background-color: $color-ui-background;
      border: 1px solid $color-ui-03;
      box-shadow: 0 0 12px 12px rgba(#000000, 0.02);

      iframe {
        border: 0;
        width: 100%;
      }
    }
  }

  @media print {
    all: unset !important;

    > div.header {
      display: none;
    }

    > div.content {
      width: auto;
      min-width: auto;
      padding: 0;
      margin: 0;

      div.document {
        //width: auto;
        //min-height: auto;
        background-color: unset;
        padding: unset;
        margin: unset;
        border: unset;
        box-shadow: unset;
      }
    }
  }
}
