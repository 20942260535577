@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";
@import "../../../../styles/colors";

div.invipo-traffic-detail-repository {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 300px;
    background-color: $color-icon-04;
    border-radius: 3px;
    padding: 12px 12px;
    z-index: $z-index-menu;
    overflow: auto;
    max-height: 75%;

    > div.title {
        @include font-proxima-semi-bold;
        font-size: 16px;
        color: $color-icon-01;
        padding: 18px 24px;
    }

    div.group {
        padding: 12px;
        font-size: 13px;

        div.title {
            @include font-proxima-semi-bold;
            color: $color-text-03;
            padding: 12px
        }

        div.item {
            @include display-flex-column;
            @include cursor-pointer;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            height: 59px;
            padding: 12px;
            margin: 0;
            border-radius: 4px;

            &:hover {
                background-color: $color-hover;
            }

            div.name {
                color: $color-icon-01;
                font-size: 14px;
            }

            &-missing div.name {
                @include font-proxima-semi-bold;
            }

            div.number {
                color: $color-text-03;
                font-size: 13px;
            }

            div.count {
                position: absolute;
                right: 0;
                padding: 4px 10px;
                background-color: $color-ui-13;
                border-radius: 12px;
                font-size: 12px;
                margin-right: 12px;
            }

            div.missing {
                position: absolute;
                right: 0;
                width: 8px;
                height: 8px;
                margin-right: 12px + 8px;
                background-color: $color-interactive-01;
                border-radius: 12px;
            }

            &.selected {
                @include cursor-pointer;
                background-color: $color-interactive-02;

                div.name {
                    color: $color-icon-03;
                }

                div.number {
                    color: $color-text-08;
                }

                div.count {
                    background-color: $color-text-08;
                }
            }
        }

        hr {
            margin: 0 12px;
            height: 1px;
            background-color: $color-ui-07;
            border: 0;
        }
    }
}