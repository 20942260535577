@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-template-form {
  @include size-width-height(1248px, 960px);
  @include position-center-optical;

  textarea {
    font-family: Consolas, "Andale Mono", "Courier New", serif !important;
    font-size: 13px !important;
    white-space: nowrap;
  }
}
