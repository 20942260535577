@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-traffic-incident-tile {
  @include cursor-pointer;
  background-color: $color-ui-background-dark;
  background-size: cover;

  > div.header {
    div.title {
      color: $color-text-01-dark;
    }
  }
}
