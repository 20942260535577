@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-video-detail {
  @include position-center-optical;
  position: relative;
  background-color: #000000;
  box-sizing: border-box;
  overflow: visible;

  &:hover {
    div.close {
      opacity: 1;
    }
  }

  > div.close {
    @include size-width-height(40px, 40px);
    @include transition-ui(opacity);
    @include cursor-pointer;
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: $color-field-01-dark;
    border-radius: 4px;
    opacity: 0;
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      left: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      background-color: $color-icon-02-dark;
      -webkit-mask-size: 24px 24px;
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-close.svg");
    }
  }

  > div.video {
    display: flex;
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    border-radius: 6px;

    iframe {
      width: 100%;
      height: 100%;
      display: block;
      border: 0;
    }
  }

  > div.preview {
    @include display-flex-row;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 12px;
    flex-wrap: wrap;

    div.image {
      @include cursor-pointer;
      width: 60px;
      height: 34px;
      background-color: #000000;
      margin: 0 4px 4px 0;
      border: 4px solid $color-field-01-dark;
      border-radius: 3px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
        margin: 0 auto;
      }

      &-selected {
        border-color: $color-interactive-01;
      }
    }
  }
}

@media only screen and (max-width: 1920px) {
  div.invipo-video-detail {
    div.video {
      width: 1280px;
      height: 720px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  div.invipo-video-detail {
    div.video {
      width: 1024px;
      height: 576px;
    }
  }
}
