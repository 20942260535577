@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-camera-detail {
  @include size-width-height(460px, 100%);
  box-sizing: border-box;

  div.snapshot {
    @include cursor-pointer;
    margin-bottom: 16px;
    border-radius: 6px;
    overflow: hidden;
  }
}
