@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

body {
  @include transition-ui(opacity, 300ms);
  display: flex;
  flex-flow: row;
  overflow: hidden;

  &.view-home {
    background-color: #090c25;
  }

  &.debug {
    div[component] {
      box-sizing: border-box;
      border: 1px dashed #dd0000 !important;
    }
  }

  &.project-crossulmde {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #181121;
      background-image: url("~muklit/assets/images/cities/ulm-bg.jpg");
    }
  }

  &.project-crosshrkcz {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #14223c;
      background-image: url("~muklit/assets/images/cities/hradec_kralove-bg.jpg");
    }
  }

  &.project-alamenvisk {
    &.view-home {
      background-size: cover;
      background-color: #355178;
      background-image: url("~muklit/assets/images/cities/esbjerg-02-bg.jpg");
    }
  }

  &.project-crossesbdk {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #355178;
      background-image: url("~muklit/assets/images/cities/esbjerg-02-bg.jpg");
    }
  }

  &.project-verdoalbdk {
    &.view-home {
      background-size: cover;
      background-color: #092044;
      background-image: url("~muklit/assets/images/cities/aalborg-bg.jpg");
    }
  }

  &.project-krnappilcz {
    &.view-home,
    &.view-dashboard  {
      background-size: cover;
      background-color: #105a42;
      background-image: url("~muklit/assets/images/cities/krnap-bg.jpg");
    }
  }

  &.project-crosshavcz {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #1e1e36;
      background-image: url("~muklit/assets/images/cities/havirov-bg.jpg");
    }
  }

  &.project-crossvejdk {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #182f58;
      background-image: url("~muklit/assets/images/cities/vejle-bg.jpg");
    }
  }

  &.project-crossciscz {
    &.view-home {
      background-size: cover;
      background-color: #142981;
      background-image: url("~muklit/assets/images/cities/cross-03-bg.jpg");
    }
  }

  &.project-crossrsdcz {
    &.view-home {
      background-size: cover;
      background-color: #32617e;
      background-image: url("~muklit/assets/images/cities/RSD-bg.jpg");
    }
  }

  &.project-dotswpregr {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #033f73;
      background-image: url("~muklit/assets/images/cities/preveza-bg.jpg");
    }
  }

  &.project-dotswkalgr {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #0d3357;
      background-image: url("~muklit/assets/images/cities/kalamaria-bg.jpg");
    }
  }

  &.project-dotswpatgr {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #04345c;
      background-image: url("~muklit/assets/images/cities/patras-bg.jpg");
    }
  }

  &.project-dotswvolgr {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #04345c;
      background-image: url("~muklit/assets/images/cities/volos-bg.jpg");
    }
  }

  &.project-crossegogr {
    &.view-home {
      background-size: cover;
      background-color: #142b21;
      background-image: url("~muklit/assets/images/cities/egnatia-odos-bg.jpg");
    }
  }

  &.project-crosspocvn {
    &.view-home {
      background-size: cover;
      background-color: #1c263f;
      background-image: url("~muklit/assets/images/cities/vietnam-bg.jpg");
    }
  }

  &.project-inmeehanvn {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #1c263f;
      background-image: url("~muklit/assets/images/cities/hanoi-bg.jpg");
    }
  }

  &.project-inmeeangvn {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #1c263f;
      background-image: url("~muklit/assets/images/cities/an_giang-bg.jpg");
    }
  }
}
