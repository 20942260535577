@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-drawer-menu {
  @include size-width-height(320px, 100%);

  >:first-child {
    padding-top: 24px !important;
  }

  div.group {
    padding: 16px 12px 16px 12px;

    &+div.group {
      padding-top: 0;
    }

    div.title {
      @include font-proxima-regular;
      font-size: 13px;
      overflow: hidden;
      //text-transform: uppercase;
      padding: 4px 12px 7px 12px;
    }

    div.item {
      @include display-flex-row;
      @include cursor-pointer;
      align-items: center;
      overflow: hidden;
      padding: 8px 12px 9px 12px;
      border-radius: 4px;

      div.label {
        @include flex-auto-fill;
        @include font-proxima-semi-bold;
        font-size: 14px;
      }

      &-disabled {
        cursor: default !important;
      }
    }

    &-separated {
      padding-bottom: 12px;
    }
  }

  &-style-light {
    background-color: $color-field-01;

    div.group {
      div.title {
        color: $color-text-03;
      }

      &-separated {
        border-bottom: 1px solid $color-ui-03;
      }

      div.item {
        &:hover {
          background-color: $color-hover;
        }

        div.label {
          color: $color-text-01;
        }

        &-selected {
          background-color: $color-interactive-02 !important;

          div.label {
              color: $color-text-04;
          }
        }

        &-disabled {
          &:hover {
            background-color: transparent !important;
          }

          div.label {
            color: $color-disabled-03 !important;
          }
        }
      }
    }
  }

  &-style-dark {
    background-color: $color-field-01-dark;

    div.group {
      div.title {
        color: $color-text-03-dark;
      }

      &-separated {
        border-bottom: 1px solid $color-ui-03-dark;
      }

      div.item {
        &:hover {
          background-color: $color-hover-dark;
        }

        div.label {
          color: $color-text-01-dark;
        }

        &-selected {
          background-color: $color-interactive-02-dark !important;

          div.label {
            color: $color-text-04-dark;
          }

          div.icon {
            background-color: $color-text-04-dark;
          }
        }

        &-disabled {
          div.label {
            color: $color-disabled-03-dark !important;
          }

          div.icon {
            background-color: $color-disabled-03-dark;
          }

          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  @media print {
    display: none;
  }

  @media only screen and (max-width: 1280px) {
    @include size-width-height(200px, 100%);
  }
}

