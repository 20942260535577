@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "~muklit/styles/positions";

div.invipo-detection-report {
  @include flex-auto-fill;
  height: 100%;

  div.form {
    div.fieldset-general {
      @include display-flex-row;
      flex-wrap: wrap;

      div.field-interval {
        margin-right: 32px;
      }
    }
  }

  div.snapshots {
    @include display-flex-row;
    flex-wrap: wrap;
    margin-bottom: 24px;

    div.snapshot {
      @include transition-ui(opacity);
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      margin: 0 4px 4px 0;
      background-color: $color-hover;

      img {
        @include cursor-pointer;
        display: block;
        width: 100%;
      }

      div.count {
        @include font-proxima-regular;
        @include font-size-color(11px, $color-text-01-dark);
        position: absolute;
        width: 12px;
        left: 4px;
        bottom: 4px;
        text-align: center;
        background-color: $color-field-01-dark;
        padding: 2px 4px;
        border-radius: 4px;
      }

      &-period-1,
      &-period-2,
      &-period-5,
      &-period-10 {
        width: 116px;
        height: 63px;
      }

      &-period-15 {
        width: 176px;
        height: 97px;
      }

      &-period-20 {
        width: 236px;
        height: 131px;
      }
    }
  }
}

