@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "~muklit/styles/positions";

div.invipo-face-search-report {
  @include flex-auto-fill;
  height: 100%;

  > div.content {
    > div.table {
      img.snapshot {
        @include cursor-pointer;
        display: block;
        border-radius: 4px;
      }
    }

    > div.form {
      div.fieldset-general {
        @include display-flex-row;
        flex-wrap: wrap;
        justify-content: space-between;

        div.field-interval {
          width: 320px;
        }

        div.field-item-id {
          width: 320px;
        }

        div.field-image {
          width: 672px;
        }
      }
    }
  }

}

