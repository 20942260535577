@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-stats-chart {
  @include flex-auto-fill;
  @include display-flex-column;
  height: 100%;
  overflow: hidden;

  div.header {
    @include display-flex-row;
    height: 40px;
    min-height: 40px;
    align-items: center;
    margin-bottom: 8px;

    div.title {
      @include flex-auto-fill;
      @include font-proxima-semi-bold;
      font-size: 19px;
      color: $color-text-02;
      margin-right: 24px;
    }
  }

  div.filter {
    @include display-flex-row;
    height: 28px;
    align-items: center;
    margin: 12px 0 16px 0;

    div.toggler {
      @include transition-ui(background-color);
      @include cursor-pointer;
      box-sizing: border-box;
      border-radius: 14px;
      padding: 2px 8px 2px 8px;
      margin: 2px 8px 2px 0;

      div.icon {
        @include size-width-height(24px, 24px);
        background-color: $color-text-03;
        -webkit-mask-size: 24px 24px;
        -webkit-mask-image: url("muklit/assets/images/icons/tools/24/24-tools-filter.svg");
      }

      &:hover {
        background-color: $color-hover;
      }

      &-selected {
        background-color: $color-highlight-03;

        &:hover {
          background-color: $color-highlight-03;
        }

        div.icon {
          background-color: $color-interactive-01;
        }
      }
    }
  }

  div.toolbar {
    @include display-flex-row;
    align-items: center;
    margin-bottom: 24px;

    div.group {
      @include font-proxima-regular;
      @include cursor-pointer;
      @include transition-ui(color);
      font-size: 14px;
      color: $color-text-03;
      white-space: nowrap;
      margin-right: 32px;

      &:hover {
        color: $color-text-02;
      }

      &-selected {
        @include font-proxima-semi-bold;
        color: $color-text-01;
      }
    }

    div.spacer {
      @include flex-auto-fill;
    }
  }

  div.chart {
    @include flex-auto-fill;
    overflow: auto;
    margin-bottom: 16px;
  }

  div.table {
    @include flex-auto-fill;
    overflow: auto;
  }

  div.legend {
    @include display-flex-row;
    padding-left: 16px;
    margin-bottom: 24px;

    div.symbol {
      @include display-flex-row;
      align-items: center;
      margin-right: 16px;
      overflow: hidden;

      &:nth-child(1) {
        div.line {
          background-color: #00a2ff;
        }
      }

      &:nth-child(2) {
        div.line {
          background-color: #ff00a2;
        }
      }

      &:nth-child(3) {
        div.line {
          background-color: #aaaaaa;
        }
      }

      div.line {
        @include size-width-height(20px, 4px);
        background-color: $color-text-05;
        margin-right: 8px;
      }

      div.label {
        @include font-proxima-regular;
        color: $color-text-01;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  div.highlights {
    @include display-flex-row;

    div.highlight {
      width: 220px;
      min-width: 160px;
      box-sizing: border-box;
      padding: 16px;
      background-color: $color-field-01;
      margin-right: 8px;
      overflow: hidden;
      border-radius: 8px;

      &:last-child {
        margin-right: 0;
      }

      div.label {
        @include font-proxima-regular;
        color: $color-text-01;
        font-size: 14px;
        margin-bottom: 8px;
      }

      div.value {
        @include font-proxima-semi-bold;
        color: $color-text-01;
        font-size: 19px;
        margin-bottom: 4px;
      }

      div.description {
        @include font-proxima-regular;
        color: $color-text-05;
        font-size: 12px;
      }
    }
  }

  div.empty {
    @include flex-auto-fill;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    div.image {
      @include size-width-height(240px, 180px);
      background-image: url("~muklit/assets/images/illustrations/other/no_data.svg");
      margin: 64px 0 24px 0;
    }

    div.text {
      @include font-proxima-regular;
      font-size: 14px;
      color: $color-text-02;
      text-align: center;
      margin-bottom: 80px;
    }
  }

}
