@mixin size-width($width) {
  width: $width;
  min-width: $width;
}

@mixin size-height($height) {
  height: $height;
  min-height: $height;
}

@mixin size-width-height($width, $height) {
  width: $width;
  height: $height;
  min-width: $width;
  min-height: $height;
}

@mixin display-flex-row {
  display: flex;
  flex-flow: row;
}

@mixin display-flex-column {
  display: flex;
  flex-flow: column;
}

@mixin flex-auto-fill {
  flex: 1 1 auto;
}

@mixin font-proxima-light {
  font-family: "Invipo Light", sans-serif;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin font-proxima-regular {
  font-family: "Invipo Regular", sans-serif;;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin font-proxima-medium {
  font-family: "Invipo Medium", sans-serif;;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin font-proxima-semi-bold {
  font-family: "Invipo Semibold", sans-serif;;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin font-proxima-bold {
  font-family: "Invipo Bold", sans-serif;;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin font-proxima-extra-bold {
  font-family: "Invipo Extrabold", sans-serif;;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@mixin font-size-color($size, $color) {
  font-size: $size;
  color: $color;
}

@mixin cursor-pointer {
  cursor: pointer !important;
  user-select: none;
}

@mixin position-center-middle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin position-center-optical {
  position: absolute !important;
  left: 50%;
  top: 48%;
  transform: translate(-50%, -50%);
}

@mixin display-flex-center-middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin transition-ui($property, $duration: 100ms, $delay: 0ms) {
  transition: all $duration cubic-bezier(0, 0, 0.38, 0.9) $delay;
  transition-property: #{$property};
}

@mixin animation-ui($keyframes, $duration: 150ms) {
  animation: $keyframes $duration cubic-bezier(0, 0, 0.38, 0.9);
}

@mixin box-shadow() {
  //box-shadow: 0 2px 6px rgba(#000000, 0.2);
  box-shadow: 0 4px 14px 0 rgba(#000000, 0.20);
}
