@import "~muklit/styles/_positions.scss";
@import "~muklit/styles/_colors.scss";
@import "~muklit/styles/_mixins.scss";

div.invipo-item-geometry-form {
  position: absolute;
  left: 54px + 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  animation: keyframes-detail-grow-in 300ms;
  overflow: visible;

  &:hover {
    div.close {
      opacity: 1;
    }
  }

  > div.close {
    @include size-width-height(40px, 40px);
    @include transition-ui(opacity);
    @include cursor-pointer;
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: $color-field-01-dark;
    border-radius: 4px;
    opacity: 0;
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      left: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      background-color: $color-icon-02-dark;
      -webkit-mask-size: 24px 24px;
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-close.svg");
    }
  }

  > div.map {
    @include flex-auto-fill;
    position: relative;
    height: 100%;
    overflow: auto;

    div.mapboxgl-map {
      border-radius: 6px;

      div.mapboxgl-ctrl-top-left {
        left: 328px;
      }
    }
  }
}
