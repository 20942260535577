@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";
@import "../../../styles/colors";

div.invipo-traffic-detail-schema {
    @include display-flex-row;
    @include flex-auto-fill;
    @include font-proxima-regular;
    position: relative;

    div.toolbar {
        @include display-flex-row;
        position: absolute;
        right: 8px;
        bottom: 8px;

        > div:first-child {
          margin-right: 8px;
        }
    }

    div.controls {
        @include display-flex-row;

        > div {
            @include cursor-pointer;
            @include display-flex-row;
            align-items: center;
            background-color: $color-icon-04;
            border-radius: 3px;
            margin-right: 8px;
            padding: 8px;

            div.icon {
                @include size-width-height(24px, 24px);
                -webkit-mask-size: 24px 24px;
            }
        }

        div.add {
            div.icon {
                background-color: $color-icon-02;
                -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-plus.svg");
            }
        }

        div.undo {
            @include font-proxima-semi-bold;
            color: $color-interactive-01;
            padding-right: 16px;

            div.icon {
                margin-right: 12px;
                background-color: $color-interactive-01;
                -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-reset.svg");
            }
        }
    }

}