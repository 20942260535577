@import "muklit/styles/mixins";
@import "muklit/styles/colors";

div.partial-flag {
  display: inline-block;
  font-size: 12px;
  color: $color-icon-02;
  background-color: $color-ui-03;
  border-radius: 12px;
  padding: 4px 6px;
  margin: 2px 2px;
}
