@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-notification-form {
  position: absolute;
  left: 54px + 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  animation: keyframes-detail-grow-in 300ms;
  background-color: $color-ui-background;

  div.content {
    scrollbar-gutter: stable;

    form {
      width: 720px;
      margin: 0 auto;
    }

    div.fieldset {
      background-color: $color-field-01;
      border-radius: 6px;
      padding: 24px 24px 16px 24px;
      margin-bottom: 40px;

      &-general {
        @include display-flex-row;
        flex-wrap: wrap;

        div.field:nth-child(2) {
          margin-right: 32px;
        }
      }

      &-condition {
        @include display-flex-row;
        flex-wrap: wrap;

        div.field:nth-child(2n-1) {
          margin-right: 32px;
        }
      }
    }
  }
}
