@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "~muklit/styles/positions";

div.invipo-city-map {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;

  div.map {
    @include flex-auto-fill;
    position: relative;
    height: 100%;
    background-color: #e8e8e8;
    //overflow: hidden;
    //z-index: 0;

    div.placeholder {
      position: absolute;

      &-navigator {
        left: 8px;
        top: 8px;
        z-index: 1;
      }

      &-toolbar {
        left: 336px;
        top: 8px;
        z-index: 1;
      }

      &-list {
        left: 8px;
        top: 8px;
        bottom: 8px;
        z-index: 2;
      }

      &-weather {
        right: 8px;
        top: 8px;
        z-index: 1;
      }

      &-control {
        right: 8px;
        bottom: 8px;
        z-index: 1;
      }
    }
  }

  @media print {
    display: none;
  }
}
