@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-tile {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px;
  margin: 0 0 8px 0;
  background-color: $color-ui-background;
  user-select: none;

  > div.header {
    @include display-flex-row;
    height: 24px;
    align-items: center;
    margin-bottom: 16px;

    div.title {
      @include flex-auto-fill;
      @include font-proxima-semi-bold;
      @include font-size-color(14px, $color-text-01);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    div.icon {
      @include size-width-height(24px, 24px);
      margin: 0 -4px 0 2px;
      -webkit-mask-size: 24px 24px;

      &-success {
        display: none;
        background-color: $color-status-ok;
        -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-success.svg");
      }

      &-tip {
        background-color: $color-status-warn;
        -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-tip.svg");
      }

      &-warning {
        background-color: $color-status-warn;
        -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-warning.svg");
      }

      &-error {
        background-color: $color-status-error;
        -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-error.svg");
      }
    }
  }

  > div.kpis {
    @include display-flex-row;
    flex-wrap: wrap;
    margin: 0 0 16px 0;

    div.kpi {
      width: 50%;
      min-width: 50%;
      margin-bottom: 24px;

      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }

      div.label {
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
      }

      div.value {
        @include font-proxima-medium;
        @include font-size-color(14px, $color-text-01);
        margin: 2px 0;
      }

      div.description {
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
      }
    }
  }

  > div.bar {
    @include display-flex-row;
    height: 12px;
    min-height: 12px;
    margin: 12px 0 4px 0;
    background-color: $color-hover;
    border-radius: 6px;
    overflow: hidden;

    div.symbol {
      height: 100%;
      background-color: $color-ui-03;

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
  }

  > div.legend {
    margin-top: 12px;

    div.description {
      @include font-proxima-regular;
      @include font-size-color(13px, $color-text-03);
      margin-bottom: 8px;
    }

    div.row {
      @include display-flex-row;
      align-items: center;
      padding: 4px 0;
      box-sizing: border-box;

      div.symbol {
        @include size-width-height(10px, 10px);
        border-radius: 50%;
        margin-right: 8px;

        &-unknown {
            background-color: #aaaaaa;
        }
      }

      div.label {
        @include font-proxima-medium;
        @include font-size-color(13px, $color-text-02);
        padding-right: 4px;
      }

      div.count {
        @include flex-auto-fill;
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
        text-align: right;
        padding-right: 4px;
      }

      div.percent {
        @include font-proxima-medium;
        @include font-size-color(13px, $color-text-03);
        padding-right: 4px;
        text-align: right;
      }

      &-cursor {
        @include cursor-pointer;
      }
    }

    div.range {
      @include display-flex-row;
      position: relative;
      height: 24px;
      align-items: center;
      user-select: none;

      div.values {
        @include display-flex-row;
        width: 100%;
        height: 24px;
        justify-content: space-between;
        align-items: center;
        background-color: #dddddd;
        padding: 0 8px;
        border-radius: 12px;

        div.value {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-field-02);
        }
      }

      div.average {
        position: absolute;
        left: 0;
        top: -4px;
        width: 2px;
        height: 32px;
        background-color: $color-ui-05;
      }
    }
  }

  > div.list {
    margin-top: 12px;

    div.description {
      @include font-proxima-regular;
      @include font-size-color(13px, $color-text-03);
      margin-bottom: 8px;
    }

    div.row {
      @include display-flex-row;
      align-items: center;
      padding: 4px 0;
      box-sizing: border-box;

      div.symbol {
        @include size-width-height(10px, 10px);
        border-radius: 50%;
        margin-right: 8px;
      }

      div.name {
        @include font-proxima-medium;
        @include font-size-color(13px, $color-text-02);
        padding-right: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &-link {
          @include cursor-pointer;
        }
      }

      div.label {
        @include flex-auto-fill;
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
        text-align: right;
        padding-right: 4px;
      }
    }
  }

  > div.chart {
    div.label {
      @include font-proxima-medium;
      @include font-size-color(13px, $color-text-02);
      margin-bottom: 8px;
    }

    div.columns {
      @include display-flex-row;
      height: 48px;
      min-height: 48px;
      overflow: hidden;

      div.column {
        position: relative;
        background-color: $color-ui-08;
        overflow: hidden;

        &:last-child {
          margin-right: 0;
        }

        div.bar {
          position: absolute;
          bottom: 0;
          width: 100%;
          background-color: $color-ui-04;
        }
      }

      &-8 {
        div.column {
          width: 32px;
          min-width: 32px;
          margin-right: 4px;
        }
      }

      &-48 {
        div.column {
          width: 4px;
          min-width: 4px;
          margin-right: 2px;
        }
      }

      &-small {
        div.column {
          height: 12px;
          min-height: 12px;
        }
      }

      &-medium {
        div.column {
          height: 24px;
          min-height: 24px;
        }
      }
    }

    div.range {
      @include display-flex-row;
      position: relative;
      justify-content: space-between;
      margin-top: 4px;

      div.value {
        @include font-proxima-regular;
        @include font-size-color(11px, $color-text-03);

        &-fixed {
          width: 48px;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      &-8 {
        div.value {
          width: 36px;
          min-width: 36px;
          overflow: hidden;
          white-space: nowrap;
          text-align: center;
        }
      }
    }
  }

  div.note {
    padding: 16px 0 0 0;
  }

  &-size-1xA {
    @include size-width(1 * 312px);
  }

  &-size-1x1 {
    @include size-width(1 * 312px);
    @include size-height(1 * 184px);
  }

  &-size-1x2 {
    @include size-width(1 * 312px);
    @include size-height(2 * 184px + 8px);
  }

  &-size-1x3 {
    @include size-width(1 * 312px);
    @include size-height(3 * 184px + 2 * 8px);
  }

  &-size-1x4 {
    @include size-width(1 * 312px);
    @include size-height(4 * 184px + 3 * 8px);
  }

  &-size-1x5 {
    @include size-width(1 * 312px);
    @include size-height(5 * 184px + 4 * 8px);
  }

  @keyframes keyframes-tile-fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
