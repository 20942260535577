@import "~muklit/styles/positions";
@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-image-tooltip {
  @include box-shadow;
  @include animation-ui(keyframes-fade-in-01);
  position: absolute;
  max-width: 480px;
  overflow: hidden;
  background-color: $color-field-01;
  z-index: $z-index-tooltip;
  border-radius: 4px;

  img {
    display: block;
    width: 100%;
  }
}
