@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-detail-panel {
  width: 720px;
  padding: 24px 32px 56px 32px;

  > div.title {
    @include display-flex-row;
    margin-bottom: 42px;

    div.label {
      @include font-proxima-semi-bold;
      @include font-size-color(19px, $color-ui-05);
      @include flex-auto-fill;
    }
  }

  > div.alerts {
    @include font-proxima-regular;
    @include font-size-color(14px, $color-text-02);
    padding: 12px 12px 12px 12px;
    margin-bottom: 24px;
    border: 2px solid rgba($color-support-01, 0.15);
    border-radius: 6px;

    &:empty {
      display: none;
    }
  }

  > div.section {
    @include display-flex-row;
    justify-content: space-between;
    margin-bottom: 18px;

    div.label {
      @include font-proxima-semi-bold;
      @include font-size-color(16px, $color-ui-05);
      @include flex-auto-fill;
    }

    div.action {
      @include font-proxima-semi-bold;
      @include font-size-color(14px, $color-interactive-01);
      @include cursor-pointer;
      padding: 2px 4px 0 4px;
    }
  }

  > div.map {
    height: 240px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: $color-field-01;
    margin-bottom: 24px;

    > div {
      border-radius: 6px;
    }

    &-tall {
      height: 484px;
    }
  }

  > div.description {
    @include font-proxima-regular;
    @include font-size-color(14px, $color-text-02);
    margin-bottom: 24px;
  }

  > div.form {
    background-color: $color-field-01;
    padding: 24px 24px 24px 24px;
    margin-bottom: 24px;
    border-radius: 6px;

    div.fieldset {
      @include display-flex-row;
      flex-wrap: wrap;
    }

    button {
      margin: 16px auto 0 auto;
    }
  }

  > div.properties {
    @include flex-auto-fill;
    background-color: $color-field-01;
    padding: 24px 24px 12px 24px;
    margin-bottom: 24px;
    border-radius: 6px;

    div.group {
      @include font-proxima-semi-bold;
      @include font-size-color(13px, $color-text-03);
      width: 100%;
      padding: 28px 0 12px 0;
      margin-bottom: 16px;
      border-bottom: 1px solid $color-ui-03;

      &:first-child {
        padding-top: 0;
      }
    }

    div.property {
      margin-bottom: 16px;

      div.label {
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
        margin-bottom: 2px;
      }

      div.value {
        @include font-proxima-medium;
        @include font-size-color(14px, $color-ui-05);

        &::after {
          content: " ";
        }

        a {
          @include font-proxima-semi-bold;
          color: $color-interactive-01;
        }
      }
    }

    div.note {
      @include font-proxima-medium;
      @include font-size-color(14px, $color-text-03);
      margin-bottom: 16px;
    }

    &-full {
      display: block;

      div.property {
        width: 100%
      }
    }

    &-half {
      @include display-flex-row;
      flex-wrap: wrap;

      div.property {
        width: 50%;
      }
    }

    &-third {
      @include display-flex-row;
      flex-wrap: wrap;

      div.property {
        width: 33.3%;
      }
    }

    &-fourth {
      @include display-flex-row;
      flex-wrap: wrap;

      div.property {
        width: 25%;
      }
    }

    &-fifth {
      @include display-flex-row;
      flex-wrap: wrap;

      div.property {
        width: 20%;
      }
    }

    div.table {
      margin-bottom: 16px;

      table {
        width: 100%;

        tr {

          &:last-child {
            td {
              border-bottom: none;
            }
          }

          th {
            @include font-proxima-semi-bold;
            @include font-size-color(13px, $color-text-03);
            padding: 12px 12px 12px 0;
            border-bottom: 1px solid $color-ui-03;
          }

          td {
            border-bottom: 1px solid $color-ui-03;
            padding: 12px 12px 8px 0;

            &.label {
              @include font-proxima-regular;
              @include font-size-color(14px, $color-text-03);
            }

            &.value {
              @include font-proxima-regular;
              @include font-size-color(14px, $color-text-01);
              overflow-wrap: anywhere;
            }

            a {
              @include font-proxima-semi-bold;
              color: $color-interactive-01;
            }
          }
        }
      }
    }
  }

  > div.chart {
    margin-bottom: 24px;

    div.columns {
      @include display-flex-row;
      background-color: $color-hover-dark;
      align-items: center;
      overflow: hidden;

      div.current {
        @include display-flex-row;
        align-items: center;
        justify-content: space-between;

        div.label {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
        }

        div.value {
          @include font-proxima-medium;
          @include font-size-color(14px, $color-ui-05);
          margin-right: 8px;
        }
      }

      div.column {
        @include display-flex-column;
        position: relative;
        height: 48px;
        min-height: 48px;
        background-color: $color-ui-08;
        overflow: hidden;
        justify-content: end;

        &:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        &:last-child {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        &:last-child {
          margin-right: 0;
        }

        div.label {
          @include font-proxima-regular;
          @include font-size-color(11px, $color-text-03);
          text-align: center;
          margin-bottom: 2px;
          //transform: rotate(-90deg);
        }

        div.bar {
          width: 100%;
          background-color: $color-ui-04;
        }

        &-transpartent {
          background-color: unset;
        }

        &-width-4-margin-2 {
          width: 4px;
          min-width: 4px;
          margin-right: 2px;
        }

        &-width-8-margin-1 {
          width: 9px;
          min-width: 9px;
          margin-right: 1px;
        }

        &-width-8-margin-2 {
          width: 9px;
          min-width: 9px;
          margin-right: 2px;
        }

        &-width-8-margin-4 {
          width: 9px;
          min-width: 9px;
          margin-right: 4px;
        }
      }

      &-24 {
        div.column {
          width: 26px;
          min-width: 26px;
          margin-right: 4px;
        }
      }

      &-30 {
        div.column {
          width: 20px;
          min-width: 20px;
          margin-right: 4px;
        }
      }

      &-96 {
        div.column {
          width: 4px;
          min-width: 4px;
          margin-right: 2px;
        }
      }

      &-144 {
        div.column {
          width: 3px;
          min-width: 3px;
          margin-right: 2px;
        }
      }

      &-small {
        div.column {
          height: 24px;
          min-height: 24px;
        }
      }

      &-tall {
        div.column {
          height: 256px;
          min-height: 256px;
        }
      }
    }

    div.range {
      @include display-flex-row;
      position: relative;
      justify-content: space-between;
      margin-top: 4px;

      div.value {
        @include font-proxima-regular;
        @include font-size-color(11px, $color-text-03);

        &-fixed {
          width: 40px;
          overflow: hidden;
        }

        &-bold {
          @include font-proxima-semi-bold;
        }
      }

      &-24 {
        div.value {
          width: 30px;
          min-width: 30px;
          overflow: hidden;
          text-align: center;
        }
      }
    }

    &-group {
      margin-bottom: 8px;
    }
  }
}
