@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-dashboard {
  @include flex-auto-fill;
  @include display-flex-row;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: 32px;

  > div.column {
    height: 100%;
    padding: 24px 0 24px 24px;

    > div.name {
      @include font-proxima-semi-bold;
      @include font-size-color(19px, $color-text-01-dark);
      margin-bottom: 16px;
    }

    > div.tiles {
      @include display-flex-row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-width-1 {
      @include size-width(1 * 312px);
    }

    &-width-2 {
      @include size-width(2 * 312px + 8px);
    }

    &-width-fill {
      @include flex-auto-fill;
    }
  }
}
