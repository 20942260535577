@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.muklit-search {

  div.label {
    @include font-proxima-regular;
    font-size: 12px;
  }

  div.helper {
    @include font-proxima-regular;
    font-size: 12px;
  }

  div.input {
    position: relative;
    margin-top: 4px;
    border-radius: 4px;

    &:before {
      content: "";
      position: absolute;
      width: 24px;
      height: 24px;
      left: 12px;
      top: 8px;
      -webkit-mask-size: 24px 24px;
      -webkit-mask-image: url("../../assets/images/icons/tools/24/24-tools-search.svg");
    }

    input {
      @include font-proxima-regular;
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      font-size: 14px;
      outline: none;
      padding: 0 48px;
      border-radius: 4px;
    }

    div.clear {
      @include cursor-pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      right: 12px;
      top: 10px;
      -webkit-mask-size: 20px 20px;
      -webkit-mask-image: url("../../assets/images/icons/notification/20/20-notification-disabled.svg");
    }
  }

  div.message {
    @include font-proxima-regular;
    font-size: 12px;
    margin-top: 4px;
  }

  &-transparent {
    div.input {
      input {
        background-color: transparent !important;
      }
    }
  }

  &-empty {
    div.input {
      div.clear {
        display: none;
      }
    }
  }

  &-style-light {
    div.label {
      color: $color-text-02;
    }

    div.helper {
      color: $color-text-05;
    }

    div.input {
      input {
        color: $color-text-01;
        background-color: $color-field-01;

        &::placeholder {
          color: $color-text-03;
        }

        &:hover {
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
          -webkit-text-fill-color: $color-text-01;
          -webkit-box-shadow: 0 0 0 1000px $color-field-01 inset;
        }
      }

      div.clear {
        background-color: $color-ui-04;
      }

      &:before {
        background-color: $color-icon-02;
      }
    }

    &.muklit-search-bright {
      div.input {
        input {
          background-color: $color-field-02;
        }
      }
    }

    &.muklit-search-active {
      div.input {
        input {
        }

        &:hover {
        }
      }
    }

    &.muklit-search-disabled {
      div.label {
        color: $color-disabled-02;
      }

      div.helper {
        color: $color-disabled-02;
      }

      div.input {
        input {
          cursor: not-allowed;

          &::placeholder {
            color: $color-disabled-02;
          }
        }

        &:hover {
        }
      }

      div.messae {
        color: $color-disabled-02;
      }
    }
  }

  &-style-dark {
    div.label {
      color: $color-text-02-dark;
    }

    div.helper {
      color: $color-text-05-dark;
    }

    div.input {
      input {
        color: $color-text-01-dark;
        background-color: $color-field-01-dark;

        &::placeholder {
          color: $color-text-03-dark;
        }

        &:hover {
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
          -webkit-text-fill-color: $color-text-01-dark;
          -webkit-box-shadow: 0 0 0 1000px $color-field-01-dark inset;
        }
      }

      div.clear {
        background-color: $color-ui-04-dark;
      }

      &:before {
        background-color: $color-icon-02-dark;
      }
    }

    &.muklit-search-bright {
      div.input {
        input {
          background-color: $color-field-02-dark;
        }
      }
    }

    &.muklit-search-active {
      div.input {
        input {
        }

        &:hover {
        }
      }
    }

    &.muklit-search-disabled {
      div.label {
        color: $color-disabled-02-dark;
      }

      div.helper {
        color: $color-disabled-02-dark;
      }

      div.input {
        input {
          cursor: not-allowed;

          &::placeholder {
            color: $color-disabled-02-dark;
          }
        }

        &:hover {
        }
      }

      div.messae {
        color: $color-disabled-02-dark;
      }
    }
  }
}
