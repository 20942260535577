@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-login-page {
  @include size-width-height(100%, 100%);
  @include display-flex-row;
  position: absolute;
  left: 0;
  top: 0;
  background-color: $color-ui-background;
  align-items: center;

  div.motive {
    @include display-flex-row;
    width: 800px;
    height: 100%;
    background-color: $color-interactive-01;
    background-image: url("~invipo/assets/images/login/yet.png");
    //background-size: cover;
    background-position: -75px bottom;
    background-repeat: no-repeat;
    align-items: center;

    div.logo {
      width: 218px;
      height: 47px;
      background-image: url("~muklit/assets/images/logo/47/invipo_logo_01_47_w.svg");
      margin: 0 60px 360px 60px;
    }
  }

  div.content {
    @include flex-auto-fill;
    @include display-flex-column;
    position: relative;
    min-width: 400px;
    max-width: 400px;
    height: 100%;
    //min-height: 800px;
    padding: 0 60px 0 60px;
    justify-content: center;

    div.title {
      @include font-proxima-bold;
      font-size: 40px;
      white-space: nowrap;
      margin-bottom: 10px;
    }

    div.languages {
      margin-bottom: 60px;

      span.language {
        @include font-proxima-regular;
        @include cursor-pointer;
        @include transition-ui(background-color);
        color: $color-text-01;
        font-size: 12px;
        padding: 4px 8px;
        text-transform: uppercase;
        border-radius: 12px;

        &:hover {
          background-color: $color-hover;
        }

        &.language-selected {
          text-decoration: none;
          color: $color-text-04;
          background-color: $color-interactive-01;
        }
      }
    }

    div.form {
      height: 180px;
      min-height: 180px;

      div.text {
        @include font-proxima-regular;
        color: $color-text-01;
        font-size: 14px;
        margin-bottom: 24px;

        a {
          @include cursor-pointer;
          color: $color-interactive-01;
        }
      }
    }

    div.sso {
      @include font-proxima-regular;
      width: 280px;
      color: $color-text-01;
      font-size: 14px;

      a {
        @include font-proxima-semi-bold;
        color: $color-interactive-01;
      }
    }

    div.button {
      margin: 32px 0 48px 0;
    }

    div.partner-eu-md-opd-sfdi {
      @include size-width-height(560px, 91px);
      position: absolute;
      left: 40px;
      bottom: 60px;
      background-image: url("~muklit/assets/images/images/EU_MD+OPD+SFDI.png");
    }
  }

  @keyframes keyframes-login-page-motive-fade {
    0%   {
      opacity: 0;
    }
    100% {
      opacity: 1
    }
  }

  @keyframes keyframes-login-page-content-fade {
    0%   {
      opacity: 0;
    }
    100% {
      opacity: 1
    }
  }

  @media only screen and (max-width: 1280px) {
    div.motive {
      width: 480px;
    }
  }
}

