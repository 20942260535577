@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/positions";

div.muklit-detail {
  @include display-flex-column;
  position: relative;
  overflow: hidden;

  > div.overlay {
    position: absolute;
    height: 100%;
    z-index: 2;
  }

  > div.header {
    padding: 16px 16px 16px 24px;
    @include display-flex-row;

    div.title {
      @include flex-auto-fill;
      @include font-proxima-semi-bold;
      font-size: 19px;
      padding-top: 8px;
      margin-right: 8px;

      div.subtitle {
        @include font-proxima-regular;
        font-size: 12px;
        margin-top: 2px;
      }
    }

    &-returnable {
      padding-left: 16px;

      div.title {
        margin-left: 8px;
      }
    }
  }

  > div.navigation {
    padding: 16px 24px 24px 24px;
  }

  > div.content {
    @include flex-auto-fill;
    overflow: auto;
    padding: 0 24px 24px 24px;

    > div.map {
      height: 240px;
      box-sizing: border-box;
      background-color: $color-hover;
      margin-bottom: 12px;
      border-radius: 6px;
      overflow: hidden;

      &-tall {
        height: 360px;
      }
    }

    > div.property-grid {
      margin-bottom: 24px;

      &+div.links {
        margin-top: -20px !important;
      }

      div.title {
        @include font-proxima-semi-bold;
        font-size: 14px;
        padding: 8px 0 8px 0;
      }

      div.row {
        position: relative;
        box-sizing: border-box;
        min-height: 40px;
        padding: 10px 0;

        > div.symbol {
          @include size-width-height(10px, 10px);
          margin-right: 8px;
          border-radius: 50%;
        }

        > div.name {
          @include font-proxima-regular;
          @include flex-auto-fill;
          align-content: center;
          font-size: 14px;
          white-space: nowrap;
          padding-right: 24px;
        }

        > div.timestamp {
          @include font-proxima-regular;
          position: absolute;
          top: 10px;
          right: 0;
          font-size: 14px;
        }

        > div.value {
          @include font-proxima-regular;
          font-size: 14px;

          a {
            font-size: 14px;
          }

          &-left {
            text-align: left;
          }

          &-link {
            @include cursor-pointer;
            text-decoration: underline;
          }

          &-mono {
            line-height: 130%;
            font-family: "JetBrains Mono", Consolas, "Andale Mono", "Courier New", serif;
            white-space: pre;
          }

          &-uppercase {
            text-transform: uppercase;
          }

          &-pre {
            @include font-proxima-semi-bold;
            white-space: pre-wrap;
          }
        }

        &-inline {
          @include display-flex-row;
          align-items: center;

          > div.value {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: right;
          }
        }

        &-block {
          display: block;

          > div.name {
            margin-bottom: 12px;
          }

          > div.value {
            margin-bottom: 12px;
          }
        }
      }
    }

    > div.highlights {
      @include display-flex-row;
      margin-bottom: 24px;

      div.highlight {
        flex-grow: 1;
        min-width: 220px;
        box-sizing: border-box;
        padding: 16px;
        background-color: $color-field-01;
        margin-right: 8px;
        overflow: hidden;
        border-radius: 8px;

        &:last-child {
          margin-right: 0;
        }

        div.label {
          @include font-proxima-regular;
          color: $color-text-01;
          font-size: 14px;
          margin-bottom: 8px;
        }

        div.value {
          @include font-proxima-semi-bold;
          color: $color-text-01;
          font-size: 19px;
          margin-bottom: 4px;
        }

        div.description {
          @include font-proxima-regular;
          color: $color-text-05;
          font-size: 12px;
        }
      }
    }

    > div.tile {
      @include display-flex-row;
      @include cursor-pointer;
      align-items: center;
      border-radius: 4px;
      padding: 8px 12px;
      margin-bottom: 8px;

      div.name {
        @include flex-auto-fill;
        margin-left: 8px;

        div.label {
          @include font-proxima-regular;
          font-size: 14px;
        }

        div.description {
          @include font-proxima-regular;
          font-size: 12px;
          margin-top: 2px;
        }
      }

      div.image {
        height: 40px;
        margin: 0 8px 0 4px;
        border-radius: 4px;
        overflow: hidden;

        img {
          height: 100%;
          display: block;
        }
      }
    }

    > div.links {
      @include display-flex-row;
      justify-content: right;
      margin-bottom: 24px;
    }

    > div.block {
      margin-bottom: 24px;

      &-code {
        padding: 12px 0;
      }
    }

    > div.text {
      @include font-proxima-regular;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
    }

    > div.markdown {
      @include font-proxima-regular;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
      white-space: pre-wrap;
    }
  }

  > div.footer {
    padding: 16px 24px 24px 24px;
  }

  > div.control {
    @include display-flex-row;
    justify-content: end;
    padding: 24px;

    div.button {
      @include cursor-pointer;
      @include font-proxima-semi-bold;
      @include transition-ui(background-color);
      min-width: 104px;
      height: 40px;
      font-size: 14px;
      text-align: center;
      box-sizing: border-box;
      white-space: nowrap;
      padding: 10px 12px 0 12px;
      border-radius: 4px;

      &-primary {
        &:hover {
          background-color: $color-hover-interactive-01;
        }

        color: $color-ui-01;
        background-color: $color-interactive-01;
        margin-left: 16px;
      }

      &-secondary {
        min-width: unset;
        color: $color-interactive-01;
      }

      &-left {
        margin-right: auto;
      }

      &-right {
        margin-left: auto;
      }
    }

  }

  &-wide {
    min-width: 900px;
  }

  &-style-light {
    background-color: $color-field-01;

    // Table fix, header MUST have colors due to body scrolling
    th.header {
      background-color: $color-field-01 !important;
    }

    > div.header {
      div.title {
        color: $color-text-01;
      }

      div.subtitle {
        color: $color-text-05;
      }
    }

    > div.content {
      > div.camera-image {
        @include cursor-pointer;
        position: relative;
        background-color: $color-ui-08;
        margin-bottom: 16px;
        border-radius: 6px;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
        }

        div.count {
          @include font-proxima-regular;
          @include font-size-color(12px, $color-text-01-dark);
          position: absolute;
          width: 20px;
          left: 8px;
          bottom: 8px;
          text-align: center;
          background-color: $color-field-01-dark;
          padding: 2px 4px;
          border-radius: 4px;
        }

        &-fixed {
          width: 412px;
          height: 232px;

          img {
            height: 100%;
            object-fit: cover;
          }
        }

        &-contain {
          width: 412px;
          height: 232px;

          img {
            height: 100%;
            object-fit: contain;
          }
        }
      }

      > div.property-grid {
        div.title {
          color: $color-text-01;
          border-bottom: 1px solid $color-ui-05;
        }

        div.row {
          border-bottom: 1px solid $color-ui-03;

          &:last-child {
            border-bottom: none;
          }

          > div.name {
            color: $color-text-05;
          }

          > div.timestamp {
            color: $color-text-05;
          }

          > div.value {
            color: $color-text-01;

            a {
              color: $color-text-05;
            }

            &-positive {
              color: $color-support-02;
            }

            &-negative {
              color: $color-support-01;
            }
          }

          &-disabled {
            div.value {
              color: $color-disabled-03;
            }
          }
        }
      }

      > div.block {
        &-code {
          border-top: 1px solid $color-ui-03;
          border-bottom: 1px solid $color-ui-03;
        }
      }

      > div.text {
        color: $color-text-01;
      }

      > div.tile {
        background-color: $color-ui-08;

        div.name {
          div.label {
            color: $color-text-01;
          }

          div.description {
            color: $color-text-05;
          }
        }
      }
    }

    > div.control {
      div.button {
        div.label {
          color: $color-text-04;
        }

        &-cancel {
          background-color: $color-interactive-02;
        }

        &-confirm {
          background-color: $color-interactive-01;

          &:hover {
            background-color: $color-active-interactive-01;
          }
        }

        &-escalated {
          background-color: $color-danger;

          &:hover {
            background-color: $color-hover-danger;
          }
        }
      }
    }
  }

  &-style-dark {
    background-color: $color-field-01-dark;

    // Table fix, header MUST have colors due to body scrolling
    th.header {
      background-color: $color-field-01-dark !important;
    }

    > div.header {
      div.title {
        color: $color-text-01-dark;
      }

      div.subtitle {
        color: $color-text-05-dark;
      }
    }

    > div.content {
      > div.property-grid {
        div.title {
          color: $color-text-01-dark;
          border-bottom: 1px solid $color-ui-05-dark;
        }

        div.row {
          border-bottom: 1px solid $color-ui-03-dark;

          > div.name {
            color: $color-ui-05-dark;
          }

          > div.timestamp {
            color: $color-ui-05-dark;
          }

          > div.value {
            color: $color-text-01-dark;

            a {
              color: $color-text-05-dark;
            }
          }

          &-disabled {
            div.value {
              color: $color-disabled-03-dark;
            }
          }
        }
      }

      > div.block {
        &-code {
          border-top: 1px solid $color-ui-03;
          border-bottom: 1px solid $color-ui-03;
        }
      }

      > div.actions {
        div.action {
          &:hover {
            background-color: $color-hover-dark;
          }
        }
      }

      > div.text {
        color: $color-text-01-dark;
      }

      > div.tile {
        background-color: $color-ui-08-dark;

        div.name {
          div.label {
            color: $color-text-01-dark;
          }

          div.description {
            color: $color-text-05-dark;
          }
        }
      }
    }

    > div.control {
      div.button {
        div.label {
          color: $color-text-04-dark;
        }

        &-cancel {
          background-color: $color-interactive-02-dark;
        }

        &-confirm {
          background-color: $color-interactive-01-dark;

          &:hover {
            background-color: $color-active-interactive-01-dark;
          }
        }

        &-escalated {
          background-color: $color-danger-dark;

          &:hover {
            background-color: $color-hover-danger-dark;
          }
        }
      }
    }
  }

  &.muklit-overlay-visible {
    box-shadow: 0 1px 16px rgba(#000000, 0.2);
    border-radius: 6px;
  }

  @keyframes keyframes-detail-s-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes keyframes-detail-slide-in {
    0% {
      opacity: 0;
      top: 47%;
    }
    100% {
      opacity: 1;
      top: 48%;
    }
  }

  @keyframes keyframes-detail-grow-in {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes keyframes-detail-shake {
    0% {
      transform: translate(-50%, -50%)
    }
    25% {
      transform: translate(-51%, -50%)
    }
    50% {
      transform: translate(-49%, -50%)
    }
    75% {
      transform: translate(-51%, -50%)
    }
    100% {
      transform: translate(-50%, -50%)
    }
  }
}
