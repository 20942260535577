@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../../styles/colors";

div.invipo-home-screen {
  @include transition-ui(opacity);
  @include flex-auto-fill;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  padding: 56px;

  div.invipo {
    @include display-flex-row;
    margin-bottom: 40px;

    div.logo {
      width: 112px;
      height: 112px;
      background-color: #000000;
      background-image: url("~muklit/assets/images/logo/20/invipo_logo_in_20_w.svg");
      background-size: 32px 20px;
      background-repeat: no-repeat;
      background-position: 39px 45px;
      margin-right: 32px;
    }

    div.info {
      @include display-flex-column;

      div.label {
        @include flex-auto-fill;
        @include font-proxima-bold;
        @include font-size-color(28px, $color-text-01-dark);
      }

      div.version {
        @include font-proxima-semi-bold;
        @include font-size-color(14px, $color-text-01-dark);
      }

      div.services {
        @include font-proxima-regular;
        @include font-size-color(14px, rgba($color-text-01-dark, 0.45));
      }

      div.license {
        @include cursor-pointer;
        @include font-proxima-regular;
        @include font-size-color(14px, rgba($color-text-01-dark, 0.45));

        &:hover {
          text-decoration: underline;
        }

        &-valid {
        }

        &-expired {
        }
      }
    }
  }

  div.title {
    @include font-proxima-semi-bold;
    @include font-size-color(16px, rgba($color-text-01-dark, 0.75));
    margin-bottom: 16px;
  }

  div.domains {
    @include display-flex-row;
    flex-wrap: wrap;
    margin-bottom: 40px;

    div.domain {
      @include display-flex-column;
      @include size-width-height(216px, 216px);
      position: relative;
      box-sizing: border-box;
      background-color: #aaaaaa;
      padding: 24px;
      margin: 0 8px 8px 0;
      @include transition-ui(background-color);

      &:after {
        @include transition-ui(background-color);
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      div.icon {
        @include flex-auto-fill;
        background-size: 88px 88px;
        background-repeat: no-repeat;
      }

      div.label {
        @include font-proxima-semi-bold;
        @include font-size-color(20px, $color-text-01);
      }

      div.status {
        @include font-proxima-regular;
        @include font-size-color(14px, $color-text-01);
      }

      &-active {
        @include cursor-pointer;

        &:hover {
          &:after {
            background-color: $color-hover-dark;
          }
        }
      }

      &-infrastructure {
        background-color: $color-domain-infrastructure;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-infrastructure.svg");
        }
      }

      &-traffic {
        background-color: $color-domain-traffic;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-traffic.svg");
        }
      }

      &-roads {
        background-color: $color-domain-roads;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-roads.svg");
        }
      }

      &-transit {
        background-color: $color-domain-transit;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-transit.svg");
        }
      }

      &-parking {
        background-color: $color-domain-parking;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-parking.svg");
        }
      }

      &-safety {
        background-color: $color-domain-safety;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-safety.svg");
        }
      }

      &-energy {
        background-color: $color-domain-energy;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-energy.svg");
        }
      }

      &-environment {
        background-color: $color-domain-environment;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-environment.svg");
        }
      }

      &-waste {
        background-color: $color-domain-waste;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-waste.svg");
        }
      }

      &-services {
        background-color: $color-domain-services;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-services.svg");
        }
      }

      &-city {
        background-color: $color-domain-city;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-city.svg");
        }
      }

      &-extravilan {
        background-color: $color-domain-extravilan;

        div.icon {
          background-image: url("~muklit/assets/images/illustrations/88-3D/88-3D-environment.svg");
        }
      }
    }
  }

  div.classes {
    @include display-flex-row;
    flex-wrap: wrap;
    margin-bottom: 40px;

    div.class {
      @include display-flex-column;
      @include size-width-height(216px, 128px);
      box-sizing: border-box;
      background-color: rgba(#000000, 0.75);
      padding: 24px 24px 16px 24px;
      margin: 0 8px 8px 0;

      div.icon {
        @include flex-auto-fill;
        -webkit-mask-size: 32px 32px;
        -webkit-mask-repeat: no-repeat;
        background-color: $color-text-01-dark;

        &-traffic-counter {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-traffic_level.svg");
        }

        &-traffic-camera {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-cctv.svg");
        }

        &-bicycle-counter {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-bikesharing.svg");
        }

        &-flow-camera {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-traffic_level.svg");
        }

        &-road-weather-station {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-environment.svg");
        }

        &-ptz-camera {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-cctv.svg");
        }

        &-wim-station {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wim.svg");
        }

        &-traffic-light-controller {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-traffic-lights.svg");
        }

        &-parking-lot {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-parking.svg");
        }

        &-parking-sensor {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-parking.svg");
        }

        &-parking-sign {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-vms.svg");
        }

        &-warning-sign {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-vms.svg");
        }

        &-full-matrix-sign {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-vms.svg");
        }

        &-line-matrix-sign {
          -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-vms.svg");
        }

        &-environmental-sensor {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-environment.svg");
        }

        &-c2x-station {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-fleet.svg");
        }

        &-energy-meter {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-meters.svg");
        }

        &-street-light-controller {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-street_lighting.svg");
        }

        &-garbage-bin {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-waste.svg");
        }

        &-bluetooth-detector {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
        }

        &-radar {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-cctv-02.svg");
        }

        &-wifi-access-point {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
        }

        &-battery {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-ups.svg");
        }

        &-access-point {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-tickets.svg");
        }

        &-v2x-unit {
          -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-counter.svg");
        }

        &-security-camera {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-cctv.svg");
        }

        &-human-counter {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-cctv.svg");
        }

        &-camera {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-cctv.svg");
        }

        &-bicycle-pump-station {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-bikesharing.svg");
        }

        &-warning-light {
          -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-warning-2.svg");
        }

        &-lora-gateway {
          -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
        }

        &-svd-system {
          -webkit-mask-image: url("~muklit/assets/images/icons/devices/24/24-devices-camera.svg");
        }

        &-rlvd-system {
          -webkit-mask-image: url("~muklit/assets/images/icons/devices/24/24-devices-camera.svg");
        }
      }

      div.label {
        @include font-proxima-semi-bold;
        @include font-size-color(14px, $color-text-01-dark);
      }

      div.status {
        @include font-proxima-regular;
        @include font-size-color(14px, $color-text-03-dark);
      }
    }
  }
}

body.project-unicotskcz {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-praha.svg");
      }
    }
  }
}

body.project-crosshrkcz {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-hradec_kralove.svg");
      }
    }
  }
}

body.project-crossulmde {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-ulm.svg");
      }
    }
  }
}

body.project-crossesbdk {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-esbjerg.svg");
      }
    }
  }
}

body.project-krnappilcz {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-krnap.svg");
      }
    }
  }
}

body.project-crosshavcz {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-havirov.png");
      }
    }
  }
}

body.project-dotswpregr {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-preveza.svg");
      }
    }
  }
}

body.project-dotswkalgr {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-kalamaria.png");
      }
    }
  }
}

body.project-dotswvolgr {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-volos.png");
      }
    }
  }
}

body.project-dotswpatgr {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        //background-size: 112px 112px;
        //background-position: 0 0;
        //background-image: url("~muklit/assets/images/cities-logos/230/230-preveza.svg");
      }
    }
  }
}

body.project-inmeehanvn {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-hanoi.png");
      }
    }
  }
}

body.project-inmeeangvn {
  div.invipo-home-screen {
    div.invipo {
      div.logo {
        background-size: 112px 112px;
        background-position: 0 0;
        background-image: url("~muklit/assets/images/cities-logos/230/230-an_giang.png");
      }
    }
  }
}
