@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/positions";

div.muklit-calendar2 {
  @include display-flex-column;
  @include box-shadow;
  position: absolute;
  width: 350px;
  max-height: 600px;
  overflow: auto;
  z-index: $z-index-menu;
  background-color: $color-field-01;
  user-select: none;
  border-radius: 4px;

  div.inputs {
    padding: 16px;

    div.row {
      @include display-flex-row;
      margin-bottom: 12px;

      div.input {
        div.label {
          @include font-proxima-regular;
          color: $color-text-02;
          font-size: 12px;
          margin-bottom: 4px;
        }

        input {
          @include font-proxima-regular;
          width: 100%;
          height: 40px;
          box-sizing: border-box;
          font-size: 14px;
          color: $color-text-01;
          background-color: $color-field-02;
          outline: none;
          padding: 0 16px;
          border-radius: 4px;

          &::placeholder {
            color: $color-text-03;
          }

          &[disabled] {
            color: $color-disabled-02;
          }
        }

        &-date {
          @include flex-auto-fill;
          margin-right: 4px;
          min-width: 120px;
        }

        &-time {
          min-width: 110px;
          width: 110px;
        }
      }
    }
  }

  div.months {
    @include display-flex-row;
    justify-content: center;

    div {
      @include font-proxima-regular;
      @include display-flex-row;
      justify-content: center;
      align-items: center;

      &.icon {
        @include size-width-height(40px, 40px);
        @include cursor-pointer;
        position: relative;

        &:after {
          position: absolute;
          top: 9px;
          width: 20px;
          height: 20px;
          content: "";
          background-color: $color-icon-02;
          mask-size: 20px 20px;
          -webkit-mask-size: 20px 20px;
        }

        &-left {
          &:after {
            mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_left.svg");
            -webkit-mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_left.svg");
          }
        }

        &-right {
          &:after {
            mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_right.svg");
            -webkit-mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_right.svg");
          }
        }

        &:hover {
          background-color: $color-hover;
        }

        &-disabled {
          cursor: default !important;

          &:after {
            background-color: $color-disabled-02;
          }

          &:hover {
            background-color: unset !important;
          }
        }
      }

      &.month {
        @include font-proxima-semi-bold;
        @include size-width-height(auto, 40px);
        @include font-size-color(14px, $color-text-01);
        flex-grow: 1;
      }
    }
  }

  div.labels {
    @include display-flex-row;
    justify-content: center;
    margin-bottom: 4px;

    div {
      @include display-flex-row;
      @include font-proxima-regular;
      @include font-size-color(14px, $color-text-01);
      width: 50px;
      min-width: 36px;
      height: 36px;
      min-height: 40px;
      justify-content: center;
      align-items: center;
    }
  }

  div.week {
    @include display-flex-row;
    justify-content: center;

    div.day {
      @include display-flex-row;
      @include font-proxima-regular;
      @include font-size-color(14px, $color-text-01);
      @include cursor-pointer;
      width: 50px;
      min-width: 36px;
      height: 36px;
      min-height: 36px;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $color-hover;
      }

      &-past,
      &-future {
        @include font-size-color(14px, $color-text-03);
      }

      &-today {
        @include font-size-color(14px, $color-interactive-01);
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 5px;
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background-color: $color-interactive-01;
        }
      }

      &-selected {
        background-color: $color-interactive-01;
        color: $color-ui-02;

        &:hover {
          background-color: $color-interactive-01;
        }

        &-range {
          background-color: $color-interactive-05;

          &:hover {
            background-color: $color-interactive-05;
          }
        }

        &.day-today:after {
            background-color: $color-ui-02;
        }
      }
    }
  }

  div.toolbar {
    @include display-flex-row;
    justify-content: space-between;
    padding: 12px;

    div.button {
      @include cursor-pointer;
      @include font-proxima-semi-bold;
      @include transition-ui(background-color);
      height: 40px;
      font-size: 14px;
      text-align: center;
      box-sizing: border-box;
      padding: 10px 12px 0 12px;
      border-radius: 4px;

      &-primary {
        &:hover {
          background-color: $color-hover-interactive-01;
        }

        width: 90px;
        color: $color-ui-01;
        background-color: $color-interactive-01;
        margin-left: auto;
      }

      &-secondary {
        color: $color-interactive-01;
        margin-right: auto;
      }

      &-disabled {
        cursor: default !important;
        color: $color-disabled-01;
        background-color: $color-disabled-02;

        &:hover {
          background-color: $color-disabled-02;
        }
      }

      &-left {
        margin-right: auto;
      }

      &-right {
        margin-left: auto;
      }
    }
  }
}
