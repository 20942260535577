@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "~muklit/styles/positions";
@import "../../../styles/colors";

div.invipo-traffic-control-report {
  @include flex-auto-fill;
  height: 100%;

  div.form {
    div.fieldset-general {
      @include display-flex-row;
      flex-wrap: wrap;

      div.field-from {
        margin-right: 32px;
      }
    }
  }

  div.flow {
    position: relative;
    margin-bottom: 24px;
    break-inside: avoid;

    div.header {
      @include display-flex-row;
      padding-left: 96px;
      border-bottom: 1px solid $color-ui-03;

      div.caption {
        @include font-proxima-regular;
        @include font-size-color(12px, $color-text-01);
        text-align: center;
      }

      &-24 {
        div.caption {
          width: 26px;
          min-width: 26px;
          padding: 8px 0;
        }
      }
    }

    div.row {
      @include display-flex-row;
      align-items: center;
      border-bottom: 1px solid $color-ui-03;

      div.label {
        @include font-proxima-regular;
        @include font-size-color(14px, $color-text-01);
        width: 96px;
        min-width: 96px;
      }

      div.bar {
        @include flex-auto-fill;
        @include display-flex-row;
        user-select: none;
        height: 32px;
        margin: 2px 0;

        div.value {
          @include font-proxima-regular;
          @include font-size-color(12px, $color-text-01);
          position: relative;
          box-sizing: border-box;
          background-color: $color-text-04;
          color: $color-text-01;
          padding: 9px 0 0 0;
          //text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          border-right: 2px solid $color-ui-background;

          &:before {
            display: block;
            position: absolute;
            content: "";
            left: 0;
            top: 2px;
            width: 100%;
            height: 4px;
            border-radius: 2px;
          }

          &:after {
            display: block;
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4px;
            border-radius: 2px;
          }

          &-state-on {
            &:before {
              background-color: $color-traffic-control-state-on;
            }
          }

          &-state-off {
            &:before {
              background-color: $color-traffic-control-state-off;
            }
          }

          &-state-falshing-yellow {
            &:before {
              background-color: $color-traffic-control-state-flashing_yellow;
            }
          }

          &-state-dark {
            &:before {
              background-color: $color-traffic-control-state-dark;
            }
          }

          &-state-unknown {
            &:before {
              background-color: $color-traffic-control-state-unknown;
            }
          }

          &-mode-local {
            &:after {
              bottom: 2px;
              background-color: $color-traffic-control-mode-local;
            }
          }

          &-mode-central {
            &:after {
              background-color: $color-traffic-control-mode-central;
            }
          }

          &-mode-manual {
            &:after {
              background-color: $color-traffic-control-mode-manual;
            }
          }

          &-mode-special {
            &:after {
              background-color: $color-traffic-control-mode-special;
            }
          }

          &-mode-uknown {
            &:after {
              background-color: $color-traffic-control-mode-unknown;
            }
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  div.flow {
  }
}

