@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";
@import "../../../../styles/colors";

div.invipo-traffic-detail-element-properties {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 276px; // 300px;
    background-color: $color-icon-04;
    border-radius: 3px;
    padding: 12px 24px 18px 24px;
    z-index: $z-index-menu;

    > * {
        // margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    div.header {
        @include display-flex-row;
        @include font-proxima-semi-bold;
        font-size: 16px;
        color: $color-icon-01;
        align-items: center;
        // padding-left: 12px;
        margin-bottom: 16px;

        // div.number {
        //     color: $color-text-03;
        //     margin-right: 4px;
        // }

        // div.name {
        //     @include flex-auto-fill;
        //     color: $color-icon-01;
        // }

        div.icon {
            @include cursor-pointer;
            background-color: $color-icon-02;

            &-back {
                @include size-width-height(20px, 20px);
                -webkit-mask-size: 20px 20px;
                -webkit-mask-image: url("~muklit/assets/images/icons/tools/20/20-tools-dart_left.svg");
            }

            &-close {
                @include size-width-height(24px, 24px);
                -webkit-mask-size: 24px 24px;
                -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-close.svg");
            }
        }

        div.name {
            @include font-proxima-regular;
            text-decoration: underline;;
            color: $color-text-02;
            margin-left: 8px;
            font-size: 13px;
            flex: 1;
        }
    }

    div.title {
        @include font-proxima-semi-bold;
        font-size: 19px;
        color: $color-icon-01;
    }

    div.subtitle {
        @include font-proxima-regular;
        color: $color-text-02;
        font-size: 13px;
        margin-bottom: 24px;
    }

    div.invipo-traffic-detail-type-select,
    div.muklit-select,
    div.muklit-text-input {
        margin-bottom: 32px;
    }

    div.delete {
        @include cursor-pointer;
        @include display-flex-row;
        @include font-proxima-semi-bold;
        color: $color-interactive-01;
        align-items: center;
        font-size: 14px;

        div.icon.icon-delete {
            @include size-width-height(24px, 24px);
            background-color: $color-interactive-01;
            -webkit-mask-size: 24px 24px;
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-bin.svg");
            margin-right: 8px;
        }
    }
}