@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-traffic-incident-list {

  div.content {
    div.item {
      div.icon {
        &-forbidden-uturn {
          background-color: #ec383a;
        }

        &-obstacle-on-road {
          background-color: #ffbb00;
        }

        &-opposite-driving {
          background-color: #c128b9;
        }

        &-pedestrian-on-road {
          background-color: #298cff;
        }

        &-stationary-vehicle {
          background-color: #47cff5;
        }

        &-traffic-jam {
          background-color: #47cff5;
        }

        &-speeding {
          background-color: #ec383a;
        }

        &-forbidden-uturn {
          background-color: #ffbb00;
        }

        &-forbidden-left-turn {
          background-color: #c128b9;
        }

        &-forbidden-right-turn {
          background-color: #298cff;
        }

        &-forbidden-parking {
          background-color: #47cff5;
        }

        &-yellow-line {
          background-color: #ec383a;
        }

        &-white-line {
          background-color: #ffbb00;
        }

        &-stop-line {
          background-color: #c128b9;
        }

        &-red-light {
          background-color: #ec383a;
        }

        &-direction-arrow {
          background-color: #ec383a;
        }

        &-non-motorized-vehicle-on-route {
          background-color: #298cff;
        }

        &:after {
          -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-danger.svg");
        }
      }
    }
  }

}
