@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";
@import "../../../../styles/colors";

div.invipo-traffic-detail-type-select {
    @include display-flex-row;
    flex-wrap: wrap;
    width: 100%;

    div.item {
        @include cursor-pointer;
        @include display-flex-row;
        @include size-width-height(48px, 48px);
        @include font-proxima-regular;
        box-sizing: border-box;
        font-size: 14px;
        color: $color-icon-01;
        align-items: center;
        border: 2px solid transparent;
        border-radius: 4px;
        padding: 2px;
        margin: 0 8px 8px 0;

        &:hover {
            background-color: $color-hover;
        }

        &.selected {
            border-color: $color-interactive-01;
        }

        &.selected-many {
            border-color: $color-highlight-02;
        }

        svg {
            @include size-width-height(40px, 40px);
            display: block;
        }

        &:nth-child(5n+5) {
            margin-right: 0;
        }
    }
}