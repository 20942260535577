@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-range-input {

  div.label {
    @include font-proxima-regular;
    font-size: 12px;
  }

  div.helper {
    @include font-proxima-regular;
    font-size: 12px;
  }

  div.input {
    position: relative;
    margin-top: 4px;
    border-radius: 4px;

    input {
      @include cursor-pointer;
      @include font-proxima-regular;
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      font-size: 14px;
      outline: none;
      padding: 0 40px 0 16px;
      border-radius: 4px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      top: 12px;
      right: 16px;
      -webkit-mask-size: 16px 16px;
      -webkit-mask-image: url("../../assets/images/icons/tools/16/16-tools-dropdown_arrow.svg");
      pointer-events: none;
    }
  }

  div.message {
    @include font-proxima-regular;
    font-size: 12px;
    margin-top: 4px;
  }

  &-style-light {
    div.label {
      color: $color-text-02;
    }

    div.helper {
      color: $color-text-05;
    }

    div.input {
      &:after {
        background-color: $color-icon-02;
      }

      input {
        color: $color-text-01;
        background-color: $color-field-01;

        &::placeholder {
          color: $color-text-03;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
          -webkit-text-fill-color: $color-text-01;
          -webkit-box-shadow: 0 0 0 1000px $color-field-01 inset;
        }
      }
    }

    &.muklit-range-input-bright {
      div.input {
        input {
          background-color: $color-field-02;
        }
      }
    }

    &.muklit-range-input-active {
    }

    &.muklit-range-input-invalid {
      div.message {
        color: $color-text-error;
      }
    }

    &.muklit-range-input-disabled {
      div.label {
        color: $color-disabled-02;
      }

      div.helper {
        color: $color-disabled-02;
      }

      div.input {
        &:after {
          background-color: $color-disabled-02;
        }

        input {
          cursor: default !important;
          color: $color-disabled-02;

          &::placeholder {
            color: $color-disabled-02;
          }
        }
      }

      div.message {
        color: $color-disabled-02;
      }
    }
  }

  &-style-dark {
    div.label {
      color: $color-text-02-dark;
    }

    div.helper {
      color: $color-text-05-dark;
    }

    div.input {
      &:after {
        background-color: $color-icon-02-dark;
      }

      input {
        color: $color-text-01-dark;
        background-color: $color-field-01-dark;

        &::placeholder {
          color: $color-text-03-dark;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
          -webkit-text-fill-color: $color-text-01-dark;
          -webkit-box-shadow: 0 0 0 1000px $color-field-01-dark inset;
        }
      }
    }

    &.muklit-range-input-bright {
      div.input {
        input {
          background-color: $color-field-02-dark;
        }
      }
    }

    &.muklit-range-input-active {
    }

    &.muklit-range-input-invalid {
      div.message {
        color: $color-text-error-dark;
      }
    }

    &.muklit-range-input-disabled {
      div.label {
        color: $color-disabled-02-dark;
      }

      div.helper {
        color: $color-disabled-02-dark;
      }

      div.input {
        input {
          cursor: not-allowed;

          &::placeholder {
            color: $color-disabled-02-dark;
          }
        }
      }

      div.message {
        color: $color-disabled-02-dark;
      }
    }
  }
}
