@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-message-form {
  position: absolute;
  left: 54px + 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  animation: keyframes-detail-grow-in 300ms;
  background-color: $color-ui-background;

  div.content {
    scrollbar-gutter: stable;

    form {
      width: 720px;
      margin: 0 auto;
    }

    div.fieldset {
      background-color: $color-field-01;
      border-radius: 6px;
      padding: 24px 24px 16px 24px;
      margin-bottom: 40px;

      &-time-info, &-location, &-relevance, &-direction, &-lanes, &-i-v-s, &-r-w-w, &-weather, &-info, &-cause {
        @include display-flex-row;
        flex-wrap: wrap;

        div.field {
          margin-right: 32px;

          &.hidden {
            display: none;
          }
        }

      }

      &-location {
        .field-position-type {
          flex-basis: 100%;
        }

        .field-from-longitude {
          flex-basis: 50%;
        }

        .invipo-design-map {
          width: 672px;
          height: 300px;
        }

        .invipo-basic-map {
          width: 672px;
          height: 300px;
        }
      }

      &-info {
        input {
          color: #C2C2C2;
        }
      }
    }
  }
}
