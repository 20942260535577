@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-image-input {

  div.upload {
    @include cursor-pointer;
    @include display-flex-column;
    @include transition-ui("background-color, border-color");
    height: 160px;
    box-sizing: border-box;
    padding: 12px;
    border: 2px dotted $color-ui-04;
    border-radius: 6px;
    justify-content: center;
    align-items: center;

    input {
      display: none;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    div.icon {
      width: 24px;
      height: 24px;
      background-color: $color-ui-04;
      margin-bottom: 4px;
      -webkit-mask-size: 24px 24px;
      -webkit-mask-image: url("muklit/assets/images/icons/tools/24/24-tools-upload.svg");
    }

    div.label {
      @include font-proxima-semi-bold;
      font-size: 14px;
      color: $color-ui-04;
    }

    &:hover,
    &-active {
      background-color: rgba($color: $color-interactive-01, $alpha: 0.1);
      border: 2px dotted $color-interactive-01;

      div.icon {
        background-color: $color-interactive-01;
      }

      div.label {
        color: $color-interactive-01;
      }
    }
  }

  div.message {
    @include font-proxima-regular;
    font-size: 12px;
    margin-top: 4px;
  }

  &.muklit-image-input-invalid {
    div.message {
      color: $color-text-error;
    }
  }

  &.muklit-image-input-verified {
    div.message {
      color: $color-support-02;
    }
  }
}
