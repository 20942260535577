@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "~muklit/styles/positions";

div.invipo-traffic-daily-volume-report {
  @include flex-auto-fill;
  height: 100%;

  div.form {
    div.fieldset-general {
      @include display-flex-row;
      flex-wrap: wrap;

      div.field-interval {
        margin-right: 32px;
      }
    }
  }
}

