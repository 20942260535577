@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.partial-icon {
  @include transition-ui(background-color);
  @include cursor-pointer;
  position: relative;
  border-radius: 3px;

  &:after {
    content: "";
    position: absolute;
  }

  &-size-16 {
    @include size-width-height(24px, 24px);

    &:after {
      left: 4px;
      top: 4px;
      width: 16px;
      height: 16px;
      -webkit-mask-size: 16px 16px;
    }

    &.partial-icon-close {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-close.svg");
      }
    }

    &.partial-icon-from {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-from.svg");
      }
    }

    &.partial-icon-plus {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-plus.svg");
      }
    }
  }

  &-size-24 {
    @include size-width-height(40px, 40px);

    &:after {
      left: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      -webkit-mask-size: 24px 24px;
    }

    &.partial-icon-close {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-close.svg");
      }
    }

    &.partial-icon-back {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-arrow_left.svg");
      }
    }

    &.partial-icon-add {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-plus.svg");
      }
    }

    &.partial-icon-plus {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-plus.svg");
      }
    }

    &.partial-icon-menu {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-menu.svg");
      }
    }

    &.partial-icon-reload {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-reload.svg");
      }
    }

    &.partial-icon-print {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-print.svg");
      }
    }

    &.partial-icon-export {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-export.svg");
      }
    }

    &.partial-icon-filter {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/v2/24/24-filter.svg");
      }
    }

    &.partial-icon-save {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/v2/24/24-save.svg");
      }
    }

    &.partial-icon-chart {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-graph.svg");
      }
    }

    &.partial-icon-table {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-list.svg");
      }
    }

    &.partial-icon-parking {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/parking/24/24-parking-parking.svg");
      }
    }

    &.partial-icon-time {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-time-2-03.svg");
      }
    }

    &.partial-icon-read-all {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-success-2.svg");
      }
    }

    &.partial-icon-remove-all {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-clear.svg");
      }
    }

    &.partial-icon-access {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/v2/24/24-access.svg");
      }
    }

    &.partial-icon-lock {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-lock.svg");
      }
    }
  }

  &-style-light {
    &:hover {
      background-color: $color-hover;
    }

    &:active {
      &:after {
        background-color: $color-disabled-03;
      }
    }

    &:after {
      background-color: $color-icon-02;
    }

    &.partial-icon-selected {
      background-color: $color-highlight-02 !important;
    }

    &.partial-icon-disabled {
      &:after {
        background-color: $color-disabled-02 !important;
      }

      &:hover {
        cursor: default !important;
        background-color: transparent !important;
      }
    }
  }

  &-style-dark {
    &:hover {
      background-color: $color-hover-dark;
    }

    &:active {
      &:after {
        background-color: $color-disabled-03-dark;
      }
    }

    &:after {
      background-color: $color-icon-02-dark;
    }

    &.partial-icon-selected {
      background-color: $color-highlight-02-dark !important;
    }
  }

  // Fixed style icons
  &.partial-icon-add {
    background-color: $color-interactive-01;

    &:hover {
      background-color: $color-active-interactive-01;
    }

    &:after {
      background-color: $color-text-01-dark;
    }

    &-selected {
      background-color: $color-interactive-01 !important;
    }
  }

  @media print {
    display: none;
  }
}
