@import "~muklit/styles/positions";
@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-element-tooltip {
  @include box-shadow;
  @include animation-ui(keyframes-fade-in-01);
  position: absolute;
  max-width: 300px;
  box-sizing: content-box;
  overflow: hidden;
  background-color: $color-field-01;
  z-index: $z-index-tooltip;
  border-radius: 4px;

  div.text {
    @include font-proxima-medium;
    font-size: 12px;
    color: $color-text-01;
    padding: 11px 12px 8px 8px;
  }
}
