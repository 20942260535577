@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";
@import "../../../styles/colors";

div.invipo-traffic-detail-test {
  position: absolute;
  left: 54px + 16px;
  top: 16px;
  right: 16px;
  bottom: 16px;
  animation: keyframes-detail-grow-in 300ms;
  z-index: 2;

  div.content-schema {
    @include display-flex-row;
    height: 100%;
    padding: 0;
    overflow: hidden;
  }

  @keyframes keyframes-diagram-frame-fade-in {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes keyframes-map-fade-in {
    0%, 80% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }
}
